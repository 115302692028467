import { HTTP } from './http-common';
import store from '../store/modules/userStore'

export default function setup() {
    HTTP.interceptors.request.use(function (config) {
        //console.log(store.getters.getAccessToken);
        //console.log("store.state "+Object.values(store.state))
        const token = window.localStorage.getItem('token');
        if (token) {
            //const token = token;
            if(token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            //console.log("token " + token);
        }

        //console.log("config"+ config.headers.Authorization);
        return config;
    }, function(err) {
        return Promise.reject(err);
    });

    HTTP.interceptors.response.use(function(response) {

        //check if response contains data or not
        if(response && response.data)
        {
            //OPTI-20 //reload the page if version number is increased to resolve vue build / chunk failed error
            //get latest version from API
            var latestVersion=response.data.version
            if(latestVersion)
            {
                //get current version
                var version=localStorage.getItem("build_version_number")
                if(!version)
                {
                    version="1.0.0"
                }

                //if version is older then reload the page
                if(version!==latestVersion)
                {
                    window.location.reload()
                }

                //set the latest version to storage
                localStorage.setItem("build_version_number",latestVersion)
            }
        }

        return response;
    }, function axiosRetryInterceptor(error) {
        if(error.response){
            if(error.response.status === 403)
            {
                alert("You are not authroized to access this page");
            }
            else if(error.response.status === 401){
                window.location.href = '/login';
            }
        }
        return Promise.reject(error);
    });
}
