<template>
    <div class="border-0 d-flex justify-content-center align-items-center">
      <!-- {{time}}----{{config}} -->
      <div class="d-flex justify-content-center align-items-center m-0 p-0 bg-white border rounded" id="box">
        <input class="border-0" type="string" min="0" pattern="^[0-9]+$" v-model.number="time.hours" placeholder="hh" required
        :max="limit"
        @input="$emit('update:hours', time.hours)" >
        <h4 class="m-0 fw-normal mb-1" style="color: #343434d9;">:</h4>
        <input class="border-0" type="string" max="60" pattern="^[0-9]+$" min="0" v-model.number="time.minutes" placeholder="mm" required
        @input="$emit('update:minutes', time.minutes)" >
      </div>
    </div>
  </template>

  <script>
    export default{
      data(){
        return{
          time:{hours:this.hours,minutes:this.minutes},
          limit:this.max_limit
        }
      },
      computed:{
        maxHour(){
          let hr=Math.floor(this.limit/60);
          return hr
        },
        maxMin(){
          let hr=this.limit/60;
          let shr=Math.floor(hr);
          let min=Math.round((hr-shr)*60);
          return min;
        }
      },
      watch:{
        time:{
          handler(nv,ov){
            if(nv.minutes==60){
              nv.hours++;nv.minutes=0;
              // console.log(nv.hours);
            }
            if(nv.minutes>60){
              let strMin=String(nv.minutes);
              nv.minutes=Number(strMin.substring(0,strMin.length-1));
              // console.log(nv.minutes);
            }
            if(String(nv.minutes).includes("-")){
              nv.minutes=0;
            }
            if(String(nv.hours).includes("-")){
              nv.hours=0;
            }
            if(String(nv.minutes).includes('0') && String(nv.minutes)[0]=='0'){
              let strMin=String(nv.minutes);
              let numbers=["0","1","2","3","4","5","6","7","8","9"];
              if(numbers.includes(strMin[strMin.length-1])){
                nv.minutes=Number(strMin[strMin.length-1]);
              }
            }
            if(String(nv.hours).includes('0') && String(nv.hours)[0]=='0'){
              let strHr=String(nv.hours);
              let numbers=["0","1","2","3","4","5","6","7","8","9"];
              if(numbers.includes(strHr[strHr.length-1])){
                nv.hours=Number(strHr[strHr.length-1]);
              }
            }
            if(this.limit!=''){
              // console.log("Hii")
              if(nv.hours>this.maxHour){
                nv.hours=this.maxHour
              }
              if(nv.minutes>this.maxMin && nv.hours==this.maxHour){
                nv.minutes=this.maxMin
              }
            }
            //this.hours=nv.hours
            //this.minutes=nv.minutes
            this.$emit('update-time',this.time);
          },

          deep:true,
          // immediate:true
        }
      },
      props:{
        hours:
        {
            type:[String,Number],
            default:0
        },
        minutes:
        {
            type:[String,Number],
            default:0
        },
        max_limit:{
          type:Number,
          default:0,
        }
      },
      updated(){
        //this.time={hours:this.hours,minutes:this.minutes}
        this.hours=this.time.hours
        this.minutes=this.time.minutes
        // this.$forceUpdate();
      }
    }
  </script>

  <style scoped>
    #box{
      height:32px;
      width:6rem;
    }
    input[type=string]{
      width:35px;
      border:1px solid black;
      padding:0px 5px;
      margin:0px;
      font-size:15px;
      color:#858796;
    }
    input[type=string]::placeholder{
      font-size:15px;
    }
    input[type=string]:focus{
      outline:0px;
    }

  </style>
