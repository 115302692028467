import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    currentPage: ''
  },
  mutations: {
    SET_CURRENT_PAGE: (state, page) => {
      Vue.set(state, 'currentPage',page)
    }
  },
  actions: {
    setCurrentPage: (context, page) => {
      context.commit('SET_CURRENT_PAGE', page)
    }
  }
}
