import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    currentUser: {},
    accessToken: "",
  },
  mutations: {
    SET_CURRENT_USER: (state, user) => {
      //Vue.set(state, 'currentUser', Object.assign(state.currentUser, user))
      Vue.set(state, 'currentUser',user)
    },
    SET_ACCESS_TOKEN: (state, accessToken) => {
      //Vue.set(state, 'accessToken', Object.assign(state.accessToken, accessToken))
      Vue.set(state, 'accessToken', accessToken)
    },
  },
  getters: {
    getUser: state => {
      return state.currentUser
    },
    getAccessToken: state => {
      if (typeof (state.accessToken) !== "undefined" || state.accessToken !== null) {
        return state.accessToken
      } else {
        return ""
      }
    }
  },
  actions: {
    setCurrentUser: (context, user) => {
      context.commit('SET_CURRENT_USER', user)
    },
    setAccessToken: (context, accessToken) => {
      //console.log("set access token" + accessToken);
      window.localStorage.setItem('token',accessToken)
      context.commit('SET_ACCESS_TOKEN', accessToken)
    },
    logoutUser: (context, accessToken) => {
      //console.log("set access token" + accessToken);
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('accesible_id');
      window.localStorage.removeItem('user_role');
      window.localStorage.removeItem('current_user');
      window.localStorage.removeItem('permissions');
      window.localStorage.removeItem('snapshot-params');
      context.commit('SET_ACCESS_TOKEN', '')
    }
  }
}
