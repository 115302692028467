var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',_vm._l((_vm.treeData),function(item){return _c('li',{key:item.id},[_c('a',{staticClass:"hstack justify-content-center align-items-center gap-2",attrs:{"href":"#"},on:{"click":function($event){return _vm.toggleCollapse(item)}}},[_c('div',{staticClass:"hstack gap-2 align-items-center justify-content-center"},[_c('img',{staticClass:"rounded-circle ml-auto me-1",style:({
            height: '30px',
            width: '30px',
            background: `url(${item.img})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundColor: 'rgba(0,0,0,0.8)',
          })}),_c('div',{staticClass:"vstack m-0 p-0"},[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(item.name))]),(item.designation)?_c('p',{staticClass:"m-0",staticStyle:{"font-size":"10px"}},[_vm._v(" ("+_vm._s(item.designation)+") ")]):_c('p',{staticClass:"m-0",staticStyle:{"font-size":"10px"}},[_vm._v("- - -")])]),(item.children.length)?_c('div',{staticClass:"hstack d-inline-block"},[_vm._v(" "+_vm._s(item.children.length)+" "),(item.collapsed)?_c('i',{staticClass:"fa fa-caret-down m-0",attrs:{"aria-hidden":"true"}}):_c('i',{staticClass:"fa fa-caret-up m-0",attrs:{"aria-hidden":"true"}})]):_vm._e()])]),(!item.collapsed && item.children.length)?_c('RecursiveList',{attrs:{"treeData":item.children}}):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }