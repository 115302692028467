import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// importing the helper
import interceptorsSetup from './helpers/interceptors'
// import vSelect from 'vue-select';
// Vue.component('v-select', vSelect);
// import 'vue-select/dist/vue-select.css';


//Font awesome icons gloabally available
// import {library} from '@fortawesome/fontawesome-svg-core'
// import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
// import {faGoogle,faApple} from  '@fortawesome/free-brands-svg-icons'

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../public/css/fontawesome6.2.min.css";
import "../public/css/fonticons.css";
import "../public/css/style_new.css";

//Vue  good table import
import VueGoodTable from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTable);

// To solve issue of on refresh css loss
import jQuery from 'jquery'
global.jQuery = jQuery;
var $ = global.jQuery;
window.$ = $;

import Swal from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Datepicker from 'vuejs-datepicker';
import ReadMore from 'vue-read-more';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import MyTimePickerVue from "./components/MyTimePicker.vue";
import moment from "moment";
moment.locale('en');

import VueTimePicker from "vue2-timepicker";
import 'vue2-timepicker/dist/VueTimepicker.css'

Vue.use(Swal);
Vue.component('date-picker',Datepicker);
Vue.component('multiselect',Multiselect);
Vue.component('flat-pickr',flatPickr);
Vue.component('my-time-picker-vue',MyTimePickerVue);
Vue.component('vue-time-picker',VueTimePicker);
Vue.use(ReadMore);
//Adding brand icons to the library
// library.add(faGoogle,faApple)
// Vue.component('fa',FontAwesomeIcon);

// and running it somewhere here
interceptorsSetup()

import RecursiveList from '@/components/RecursiveList.vue';
Vue.component('RecursiveList', RecursiveList);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
