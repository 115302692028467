import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/dashboard',
  //   name: 'Dashboard',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Layouts/Dashboard.vue')
  // },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Layouts/Login.vue"),
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Layouts/ForgotPassword.vue"
      ),
  },
  {
    path: "/reset-password",
    name: "ChangePassword",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Layouts/ChangePassword.vue"
      ),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Layouts/PrivacyPolicy.vue"
      ),
  },
  {
    path: "/",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Layouts/Dashboard.vue"),

    children: [
      {
        //https://optimis-new.atlassian.net/browse/OPTI-350
        //When user has active session and opens x.optimis.in/ in new tab (only 2 menu and header is seen)
        //to fix we have treated / as user wants to open the dashboard
        //if the user is not logged in he will be seeing dashboard and immediatly redirected to login as dashboard API gets 401
        path: "",
        name: "DashboardContent",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/DashboardContent.vue"
          ),
      },
      {
        path: "dashboard",
        name: "DashboardContent",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/DashboardContent.vue"
          ),
      },
      {
        path: "attendance",
        name: "Attendance",
        component: () => import("../views/Layouts/Attendance.vue"),
      },
      {
        path: "/update-attendance",
        name: "UpdateAttendance",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/UpdateAttendance.vue"
          ),
      },
      {
        path: "users",
        name: "Users",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Layouts/Users.vue"),
      },
      {
        path: "/add-todays-goal",
        name: "Todaysgoal",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/Todaysgoal.vue"
          ),
      },
      {
        path: "/add-projects",
        name: "Addproject",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/Addproject.vue"
          ),
      },
      {
        path: "/projects",
        name: "Projects",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/OldProjects.vue"
          ),
        // import( /* webpackChunkName: "about" */ '../views/Layouts/OldProjects.vue'),
      },
      {
        path: "/update-project/:id",
        name: "updateProject",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/Addproject.vue"
          ),
      },
      {
        path: "/milestones",
        name: "Milestones",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/Milestones.vue"
          ),
      },
      {
        path: "/add-milestones",
        name: "Addmilestones",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/Addmilestones.vue"
          ),
      },
      {
        path: "/update-milestone/:id",
        name: "updatemilestone",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/Addmilestones.vue"
          ),
      },
      {
        path: "/oldtasks",
        name: "OldTasks",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/OldTasks.vue"
          ),
      },
      {
        path: "/tasks",
        name: "Task",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Layouts/Task.vue"),
      },
      {
        path: "/task/add",
        name: "AddTask",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/AddTask.vue"
          ),
      },
      {
        path: "/task/update/:id",
        name: "updatetask",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/AddTask.vue"
          ),
      },
      {
        path: "/reports",
        name: "Reports",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/NewReports.vue"
          ),
      },
      {
        path: "/oldreports",
        name: "Reports",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/OldReports.vue"
          ),
      },
      {
        path: "/daily_discrepancy",
        name: "DailyDiscrepancy",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/DailyDiscrepancy.vue"
          ),
      },
      {
        path: "/add-report/:task_id",
        name: "AddReport",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/AddReports.vue"
          ),
      },
      {
        path: "/update-report/:report_id/:task_id",
        name: "UpdateReport",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/AddReports.vue"
          ),
      },
      // {
      //   path: '/old-add-user',
      //   name: 'Add',
      //   component: () =>
      //     import( /* webpackChunkName: "about" */ '../views/Layouts/Adduser.vue'),
      // },
      {
        path: "/add-user",
        name: "AddUser",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/profile_pages/profile.vue"
          ),
        beforeEnter(_, _2, next) {
          const user_role = localStorage.getItem("user_role");
          const permissions = localStorage.getItem("permissions");
          if (user_role != 1 && !permissions.includes("user.add")) {
            next("/unauthorized-page");
          } else {
            if (permissions.includes("users.add")) {
              next();
            } else {
              next("/unauthorized-page");
            }
          }
        },
      },
      {
        path: "/update-user/:id",
        name: "updatetheuser",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/Adduser.vue"
          ),
        beforeEnter(to, from, next) {
          const user_role = localStorage.getItem("user_role");
          const current_user = localStorage.getItem("current_user");
          const permissions = localStorage.getItem("permissions");
          if (user_role != 1) {
            const arr = JSON.parse(localStorage.getItem("accesible_id"));
            // const prev_location = localStorage.getItem('prev_location');
            // if (prev_location == null) {
            //   localStorage.setItem('prev_location', to.fullPath);
            // }
            let check = false;
            arr.find((obj) => {
              if (
                obj.id === Number(to.params.id) &&
                obj.id != current_user &&
                permissions.includes("users.write")
              ) {
                check = true;
              }
            });
            if (check) next();
            else {
              next("/unauthorized-page");
            }
          } else {
            if (
              permissions.includes("users.write") &&
              to.params.id != current_user
            ) {
              next();
            } else {
              next("/unauthorized-page");
            }
          }
        },
      },
      {
        path: "/clients",
        name: "Clients",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/Clients.vue"
          ),
      },
      {
        path: "/add-client",
        name: "AddClient",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/AddClient.vue"
          ),
      },
      {
        path: "/update-client/:id",
        name: "updateclient",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/AddClient.vue"
          ),
      },
      {
        path: "/sub-clients",
        name: "SubClients",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/SubClients.vue"
          ),
      },
      {
        path: "/add-sub-client",
        name: "AddSubClient",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/AddSubClient.vue"
          ),
      },
      {
        path: "/update-sub-client/:id",
        name: "updateSubclient",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/AddSubClient.vue"
          ),
      },
      // {
      //   path: '/assets',
      //   name: 'Assets',
      //   component: () => import(/* webpackChunkName: "about" */ '../views/Layouts/Assets.vue'),
      // },
      {
        path: "/assets-category",
        name: "AssetsCategory",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/AssetsCategory.vue"
          ),
      },
      {
        path: "/add-assets-category",
        name: "AddAssetsCategory",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/AddAssetsCategory.vue"
          ),
      },
      {
        path: "/update-assets-category/:id",
        name: "UpdateAssetsCategory",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/AddAssetsCategory.vue"
          ),
        props: true,
      },
      {
        path: "/assets",
        name: "Assets",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/NewAssets.vue"
          ),
      },
      {
        path: "/assets-history",
        name: "AssetsHistory",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/AssetsHistory.vue"
          ),
      },
      {
        path: "/invoice",
        name: "Invoice",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/Invoice.vue"
          ),
      },
      {
        path: "/add-invoice",
        name: "AddInvoice",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/AddInvoice.vue"
          ),
      },
      {
        path: "/update-invoice/:id",
        name: "UpdateInvoiceCategory",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/AddInvoice.vue"
          ),
        props: true,
      },
      {
        path: "/vendor",
        name: "Vendor",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/InvoiceVendor.vue"
          ),
      },
      {
        path: "/add-asset",
        name: "AddAsset",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/Addasset.vue"
          ),
      },
      {
        path: "/update-asset/:id",
        name: "updateasset",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/Addasset.vue"
          ),
      },
      {
        path: "/add-item",
        name: "AddItem",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/Additem.vue"
          ),
      },
      {
        path: "/goals",
        name: "Goals",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Layouts/Goals.vue"),
      },
      {
        path: "/add-goal",
        name: "AddGoals",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/AddGoals.vue"
          ),
      },
      {
        path: "/update-goal/:id",
        name: "UpdateGoal",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/AddGoals.vue"
          ),
      },
      {
        path: "/emailsettings",
        name: "emailsettings",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/EmailSettings.vue"
          ),
      },
      {
        path: "/quotes",
        name: "Quotes",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Layouts/Quotes.vue"),
      },
      {
        path: "/add-quotes",
        name: "AddQuotes",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/AddQuotes.vue"
          ),
      },
      {
        path: "/update-quotes/:id",
        name: "UpdateQuotes",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/AddQuotes.vue"
          ),
      },
      // {
      //     path: '/work-report',
      //     name: 'WorkReport',
      //     component: () => import(/* webpackChunkName: "about" */ '../views/Layouts/Workreport.vue'),
      // },
      {
        path: "/update-leave-application/:id",
        name: "UpdateLeaveApplication",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/AddLeave.vue"
          ),
      },
      {
        path: "/add-leave-application",
        name: "AddLeaveApplication",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/AddLeave.vue"
          ),
      },
      {
        path: "/leaves-application",
        name: "LeavesApplication",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/OldLeaves.vue"
          ),
      },
      {
        path: "/add-credit-debit",
        name: "AddCreditDebit",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/AddCreditDebit.vue"
          ),
      },
      {
        path: "/leaves-status",
        name: "LeavesStatus",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Reports/LeaveStatus.vue"
          ),
      },
      {
        path: "/notifications",
        name: "Notifications",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/Notifications.vue"
          ),
      },
      {
        path: "/add-notification",
        name: "AddNotification",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/AddNotification.vue"
          ),
      },
      // Hide due to unresponsive graphical view
      {
        path: "/orgchart",
        name: "OrgChart",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/Hierarchy.vue"
          ),
      },
      // {
      //   path: '/hierarchy-file-view',
      //   name: 'HierarchyFileView',
      //   component: () =>
      //       import ( /* webpackChunkName: "about" */ '../views/Layouts/HierarchyFileView.vue'),
      // },
      {
        path: "/unauthorized-page",
        name: "unauthorizedPage",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/UnauthorizedPage.vue"
          ),
      },
      {
        path: "/user-allocation-report",
        name: "UserAllocationReport",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/UserAllocationReport.vue"
          ),
      },
      {
        path: "/roles",
        name: "roles",
        component: () => import("../views/Layouts/Roles.vue"),
      },
      {
        path: "/roles/add",
        name: "rolesAdd",
        component: () => import("../views/Layouts/AddRole.vue"),
      },
      {
        path: "/deleted-users",
        name: "DeletedUsers",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Layouts/DeletedUsers.vue"
          ),
      },
      // {
      //   path: '/timespent-report',
      //   name: 'TimeSpentReport',
      //   component: () =>
      //     import( /* webpackChunkName: "about" */ '../views/Layouts/TimeSpentReport.vue'),
      // },
      {
        path: '/monthly-attendance-report',
        name: 'MonthlyAttendanceReport',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/Layouts/MonthlyAttendanceReport.vue'),
      },
      {
        path: '/leaves',
        name: 'Leaves',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/Layouts/Leaves.vue'),
      },
      {
        path: '/snapshot',
        name: 'Snapshot',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/Layouts/Snapshot.vue'),
      },
      {
        path: '/productivity-report',
        name: 'ProductivityReport',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/Layouts/ProductivityReport.vue'),
      },
      {
        path: '/summary-report',
        name: 'SummaryReport',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/Layouts/SummaryReport.vue'),
      },
      {
        path: '/Siteusage-report',
        name: 'SiteusageReport',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/Layouts/Siteusage.vue'),
      },
      {
        path: "/rewards",
        name: "Rewards",
        component: () => import("../views/Layouts/Rewards.vue"),
        beforeEnter(to, from, next) {
          let user_type = JSON.parse(localStorage.getItem("user_type"));
          if ([1, 2].includes(user_type)) {
            next();
          } else {
            next("/dashboard");
          }
        },
      },
      {
        path: "/templates",
        name: "Templates",
        component: () => import("../views/Layouts/Templates.vue"),
      },
      {
        path: "/prof/:id",
        name: "Profile",
        props: true,
        component: () => import("../views/profile_pages/profile.vue"),
        beforeEnter(to, from, next) {
          const user_role = localStorage.getItem("user_role");
          const current_user = localStorage.getItem("current_user");
          const permissions = localStorage.getItem("permissions");
          // console.log("from index------ ",from);
          if (user_role != 1) {
            const arr = JSON.parse(localStorage.getItem("accesible_id"));
            // const prev_location = localStorage.getItem('prev_location');
            // if (prev_location == null) {
            //   localStorage.setItem('prev_location', to.fullPath);
            // }
            let check = false;
            arr.find((obj) => {
              if (
                obj.id === Number(to.params.id) &&
                permissions.includes("users.read")
              ) {
                check = true;
              }
            });
            if (check) {
              next();
            } else {
              // console.log("hii")
              next("/unauthorized-page");
            }
          } else {
            if (permissions.includes("users.read")) {
              next();
            } else {
              next("/unauthorized-page");
            }
          }
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  // path:'*',
  base: process.env.BASE_URL,
  routes,
});

export default router;