<template>
  <ul>
    <li v-for="item in treeData" :key="item.id">
      <a
        href="#"
        @click="toggleCollapse(item)"
        class="hstack justify-content-center align-items-center gap-2"
      >
        <div class="hstack gap-2 align-items-center justify-content-center">
          <img
            :style="{
              height: '30px',
              width: '30px',
              background: `url(${item.img})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundColor: 'rgba(0,0,0,0.8)',
            }"
            class="rounded-circle ml-auto me-1"
          ></img>
          <div class="vstack m-0 p-0">
            <p class="m-0">{{ item.name }}</p>
            <p class="m-0" style="font-size: 10px" v-if="item.designation">
              ({{ item.designation }})
            </p>
            <p class="m-0" style="font-size: 10px" v-else>- - -</p>
          </div>
          <div class="hstack d-inline-block" v-if="item.children.length">
            {{ item.children.length }}
            <i
              class="fa fa-caret-down m-0"
              v-if="item.collapsed"
              aria-hidden="true"
            ></i>
            <i class="fa fa-caret-up m-0" v-else aria-hidden="true"></i>
          </div>
        </div>
      </a>
      <RecursiveList
        v-if="!item.collapsed && item.children.length"
        :treeData="item.children"
      ></RecursiveList>
    </li>
  </ul>
</template>

<script>
import RecursiveList from "./RecursiveList.vue";

export default {
  components: {
    RecursiveList,
  },
  props: ["treeData"],
  methods: {
    toggleCollapse(item) {
      // Toggle collapse state of the item
      item.collapsed = !item.collapsed;
      this.$forceUpdate();
    },
  },
};
</script>
